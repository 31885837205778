@font-face {
  font-family: "NeuePlakExtRegular";
  src: local("NeuePlak"),
    url("./fonts/NeuePlak-ExtendedRegular.otf") format("opentype");
}

@font-face {
  font-family: "NeuePlakWideRegular";
  src: local("NeuePlak"),
    url("./fonts/NeuePlak-WideRegular.otf") format("opentype");
}

@font-face {
  font-family: "RobotoMonoRegular";
  src: local("Roboto"), url("./fonts/RobotoMono-Regular.ttf") format("opentype");
}

body {
  margin: 0;
  font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
  /* 
  background: #8ed9ff;
  background: -webkit-radial-gradient(center, #8ed9ff, #7001ff);
  background: -moz-radial-gradient(center, #8ed9ff, #7001ff);
  background: radial-gradient(ellipse at center, #8ed9ff, #7001ff); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}


.ant-drawer-content {
  background: rgba(255, 255, 255, 0.8) !important;
  backdrop-filter: blur(2px);
}

.glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.loginBtn {
  padding: 20px 40px;
  cursor: pointer;
  color: #222;
  font-size: 20px;
  margin-top: 100px;
  background: none;
  border: none;
}

.ant-modal-body {
  height: 170px;
  padding-top: 30px;
  background: rgba(255,255,255,0.7);
}

.ant-modal-content {
  background: none;
}

.appIcon-rs > span, .appIcon-vd > span, .appIcon-opt > span {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 17px;

  background-size: cover;
}

.ant-table-row {
  cursor: pointer;
}
.ant-table-tbody > tr > td {
  padding: 20px !important;
}

/* 
.ant-table-thead > tr > th {
  background: #fff !important;
} */

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.markdown-body h5 {
  font-size: 21px !important;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.list-inline a {
  margin-top: -5px;
}

.ant-collapse-item {
  border-bottom: 1px solid #e3e3e3 !important;
}

.rti--container {
  padding: 15px !important;
}

.rti--input {
  height: 35px !important;
  width: 100% !important;
}

.rti--tag {
  padding: 10px !important;
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-transfer .ant-transfer-list {
  width: 300px;
}

.ant-table-cell{
  padding: 10px !important;
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-form-item .ant-form-item-explain-error {
  float: right;
}